<template>
  <div class="home">
    <Head></Head>
    <div>
        <el-carousel :interval="5000"
                 trigger="click"
                 autoplay="false"
                 :height="bannerH"
                 class="my-carousel width100">
      <el-carousel-item v-for="(item) of list" :key="item">
          <img ref="bannerH" :src="item.src" class="lunbotu" alt="轮播图" @load="setBannerH" />
      </el-carousel-item>
    </el-carousel>
    </div>
    <div class="div_adv"></div>

    <div class="div_hot_">
      <div class="div_hot_title">
        <div class="div_hot_p"><i class="fa fa-bookmark-o icon_hot"></i>
          <p class="div_hot_1p">主打热销</p>
          <p class="div_hot_2p">Our shop is a major season<br></p>
        </div>
        <div class="div_hot_underline1">
          <div class="div_hot_underline2">
          </div>
        </div>
      </div>

      <div class="div_hot_content" style="z-index: 1000;">
        <div class="div_hot_on">
          <div class="div_hot_content1">
            <div class="div_hot_content1_1">
              <div class="div_hot_content1_2">
                <div class="div_hot_content_title"><p class="div_hot_content_title_p1">The printer</p>
                  <div class=""><p class="div_hot_content_title_p2">惠普打印机</p>
                    <div class="div_hot_content1_more">
                        <a class="div_hot_content1_more_a" href="javascript:;">read more</a>
                        <div class="div_hot_content1_more_jt">
                            <i class="fa fa-caret-right div_hot_content1_jt"></i>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="div_hot_content1_img">
                  <div class="gray_filter div_hot_content1_img_block"
                       style="background-size: contain; background-position: center center; background-repeat: no-repeat;"></div>
                </div>
                <div class="div_hot_content1_wu"></div>
                <a class="link_block div_hot_content1_a" href="javascript:;"></a>
              </div>
            </div>
          </div>

          <div class="div_hot_content2"><p class="div_hot_content2_p">The shredder</p>
            <div class="div_hot_content2_img">
              <div class="gray_filter div_hot_content2_img_block"
                   style="background-size:  contain; background-position:  center center; background-repeat:  no-repeat;"></div>
            </div>
            <div class=""><p class="div_hot_content2_p2">震旦碎纸机</p>
              <div class="div_hot_content2_more">
                  <a class="div_hot_content2_more_a" href="javascript:;">read more</a>
                  <div class="div_hot_content2_more_jt">
                      <i class="fa fa-caret-right div_hot_content2_jt" data-c_e_id="icondd65790f"></i>
                  </div>
              </div>
            </div>
            <div class="div_hot_content2_wu"></div>
            <a class="link_block div_hot_content2_a" href="javascript:;"></a>
          </div>
        </div>



        <div class="div_hot_un">
          <div class="div_hot_content3"><p class="div_hot_content3_p">The scanner</p>
            <div class="div_hot_content3_img">
              <div class="gray_filter div_hot_content3_img_block"
                   style="background-size:   contain; background-position:   center center; background-repeat:   no-repeat;"></div>
            </div>
            <div class=""><p class="div_hot_content3_p2">兄弟扫描仪</p>
              <div class="div_hot_content3_more">
                  <a class="div_hot_content3_more_a" href="javascript:;">read more</a>
                  <div class="div_hot_content3_more_jt"><i class="fa fa-caret-right div_hot_content3_jt"></i></div>
              </div>
            </div>
            <div class="div_hot_content3_wu"></div>
            <a class="link_block div_hot_content3_a" href="javascript:;"></a>
          </div>


          <div class="div_hot_content4">
            <div class="div_hot_content4_1">
              <div class="div_hot_content4_2">
                <div class="div_hot_content4_title">
                    <p class="div_hot_content4_p">Attendance machine</p>
                  <div class="">
                      <p class="div_hot_content4_p2">钉钉考勤机</p>
                    <div class="div_hot_content4_more">
                        <a class="div_hot_content4_more_a" href="javascript:;">read more</a>
                        <div class="div_hot_content4_more_jt"><i class="fa fa-caret-right div_hot_content4_jt"></i></div>
                    </div>
                  </div>
                </div>
                <div class="div_hot_content4_img">
                  <div class="gray_filter div_hot_content4_img_block"
                       style="background-size:  contain; background-position:  center center; background-repeat:  no-repeat;"></div>
                </div>
                <div class="div_hot_content4_wu"></div>
                <a class="link_block div_hot_content4_a" href="javascript:;"></a>
              </div>
            </div>
          </div>


          <div class="div_hot_middle">
              <p class="div_hot_middle_p">more<br>parts<br></p>
              <a class="link_block div_hot_middle_a" href="javascript:;"></a>
          </div>
        </div>
      </div>
    </div>

      <!--  新闻资讯黑色背景区域    -->
      <div class="div_new_hei">
          <div class="div_new_hei2">
              <div style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; overflow: hidden; pointer-events: none; visibility: hidden; z-index: -100;">
                  <div class="div_news" style="background-position: 50% 50%; background-size: 100%; background-repeat: no-repeat; position: fixed; top: 0px; left: 0px; width: 100%; height: 1800px; overflow: hidden; pointer-events: none; margin-left: 0px; margin-top: -354.938px; visibility: visible;">

                  </div>
              </div>
          </div>
      </div>

      <div>
          <div class="div_news_title">
              <div class="div_news_p"><i class="fa fa-bookmark-o icon_hot"></i>
                  <p class="div_news_1p">城市展厅</p>
                  <p class="div_news_2p">Knowledge of office equipment solutions <br>Sincere goods welcome you.</p>
              </div>
              <div class="div_news_underline1">
                  <div class="div_news_underline2"></div>
              </div>

              <div class="div_more">
                  <a class="a_more" href="javascript:;">read more</a>
                  <div class="div_more_jt">
                      <i class="fa fa-caret-right more_jt"></i>
                  </div>
              </div>
          </div>

          <div class="div_outside_orange">
              <div class="div_inside_orange">
                  <div class="div_orange_content div_orange_content1"  v-for="(item) of orange_list" :key="item">
                    <div class="orange_content">
                        <div class="gray_filter inline_block image_block orange_image_block" :src="item.src" :style="{backgroundImage: 'url(' + item.src + ')'}"
                             style="background-size: cover;background-repeat: no-repeat;: ;background-position: center center;"></div>
                        <div class="div_orange_title">
                            <div class="orange_title"><p class="orange_title_p orange_title_p1">诚货智慧办公，城市展厅。</p>
                                <div class="div_orange_time"><p class="div_orange_time_p">2021-02-01 00:00</p>
                                </div>
                            </div>
                        </div>
                        <a class="link_block link_block_" href="javascript:;"></a>
                    </div>
                  </div>
              </div>
          </div>

          <div class="footer_content">
              <div class="footer_content2">
                  <div class="footer_content3">
                      <div class="footer_info">
                          <p class="footer_info_p">
                              © 2021 宁波综瑞商务信息服务有限公司 - ChengHuo. All Rights Reserved.
                          </p>

                          <a class="footer_info_guohui_a" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33020302000892" target="_blank">
                              <img style="vertical-align: middle;" src="../assets/guohui.png">  浙公网安备 33020302000892号
                          </a>

                          <a class="footer_info_icp_a" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">
                              浙ICP备19040812号-1
                          </a>
                      </div>
                  </div>
              </div>
          </div>

      </div>

  </div>
</template>

<script>
// @ is an alias to /src
import Head from "../components/Head";

export default {
  name: "home",
  components: {
    Head
  },
    data () {
        return {
            bannerH:'',
            list: [
                { src: require("../assets/三.1920x1200.jpg") },
                { src: require("../assets/四.1920x1200.jpg") },
                { src: require("../assets/五.1920x1200.jpg") },
                { src: require("../assets/六.1920x1200.jpg") },
                ],
            orange_list:[
                { src: require("../assets/DSC01369.jpg") },
                { src: require("../assets/DSC01390.jpg") },
                { src: require("../assets/DSC01391.jpg") },
                { src: require("../assets/DSC01397.jpg") },
                ]
        }
    },
    methods:{
        setBannerH(){
            // this.bannerH = document.body.clientWidth / 4 + 'px'
            // this.xitongH = document.body.clientHeight + 'px';
            this.bannerH = this.$refs.bannerH[0].height + 'px';
        }
    },
    mounted(){
        this.setBannerH()
        window.addEventListener('resize', () => {
            this.bannerH = document.body.clientWidth / 4 + 'px'

            // this.bannerH = this.$refs.bannerH[0].height + 'px';
            this.setBannerH();
        }, false)
    },
    created(){}
};
</script>
<style>
  @import "../assets/css/home.css";
</style>
