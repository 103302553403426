<template>
    <div class="Head">
        <div class="">
            <a class="headLogo">
                <p>
                    <br>
                </p>
            </a>
        </div>
        <div class="headDiv">
            <el-menu
                    :default-active="activeIndex2"
                    mode="horizontal"
                    background-color="transparent"
                    text-color="#fff2cc"
                    active-text-color="#e34646"
                    class="div_head_tab"
                    >
                <el-menu-item class="head_tab" index="1"><a>首页</a></el-menu-item>
                <el-menu-item class="head_tab" disabled index="2"><a>关于</a></el-menu-item>
                <el-menu-item class="head_tab" disabled index="3"><a>新闻</a></el-menu-item>
                <el-menu-item class="head_tab" disabled index="4"><a>产品</a></el-menu-item>
                <el-menu-item class="head_tab" disabled index="5"><a>联系</a></el-menu-item>
            </el-menu>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Head",
        data() {
            return {
                activeIndex: '1',
                activeIndex2: '1'
            };
        },
        methods: {
            handleSelect(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>

<style scoped>
    .Head{
        position: relative;
        z-index: 1000;
        margin-top: 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: rgba(51, 51, 51, 0.76);
        top: 0px;
        border-radius: 0px;
        margin-bottom: 0px;
        border-style: none;
        padding-right: 0px;
        /*min-height: 50px;*/
    }
    .headLogo{
        display: table-cell;
        vertical-align: middle;
        float: left;
        background-size: contain;
        background-repeat: no-repeat;
        width: 50px;
        position: relative;
        left: 25px;
        top: 10px;
        z-index: 0;
        background-image:url("../assets/二.1334x1334.png");
    }
    .div_head_tab{
        border:none!important;
    }

    .headDiv{
        float:right;
        top: auto;
    }

    .head_tab{
        padding-left: 10px;
        padding-right: 10px;
        font-weight: bold;
        letter-spacing: 2px;
        transform: rotate(0deg) scale(1) translate(0%, 0%);
        transition: all 0.5s ease;
        margin-left: 30px!important;
        margin-right: 30px!important;
    }

    .head_tab:hover a{
        color: #e34646;
        transform: rotate(0deg) scale(1) translate(0%, 0%);
        transition: All 0.5s ease;
    }

    .head_tab:hover{
        background-color: transparent !important;
        /*transform: rotate(0deg) scale(1) translate(0%, 0%);*/
        /*transition: All 0.5s ease;*/
    }


</style>
